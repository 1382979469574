import { utilsFontSizeClass, utilsTextColorClass } from '@/utils/computed-classes'

export default {
  name: 'Headline',
  props: {
    fontSize: {
      type: String,
      default: 'xl',
      required: false
    },
    lgFontSize: {
      type: String,
      default: '2xl',
      required: false
    },
    textColor: {
      type: String,
      default: null,
      required: false
    },
    headlineType: {
      type: String,
      default: 'h2',
      required: false
    },
    headline: {
      type: [Object, String],
      default: () => ({})
    },
    textTransform: {
      type: String,
      default: 'uppercase'
    },
    textAlign: {
      type: String,
      default: ''
    },
    marginBottom: {
      type: String,
      default: '0'
    },
    lgMarginBottom: {
      type: String,
      default: '0'
    }
  },
  computed: {
    fontSizeClass () {
      return utilsFontSizeClass(this.fontSize, this.lgFontSize)
    },
    textColorClass () {
      return utilsTextColorClass(this.textColor)
    },
    textTransformClass () {
      return {
        uppercase: 'uppercase tracking-widest',
        lowercase: 'lowercase',
        capitalize: 'capitalize',
        normal: 'normal-case'
      }[this.textTransform]
    },
    marginClass () {
      const classes = {
        0: 'mb-0',
        4: 'mb-4',
        8: 'mb-8',
        16: 'mb-16'
      }

      const lgClasses = {
        0: 'lg:mb-0',
        4: 'lg:mb-4',
        8: 'lg:mb-8',
        16: 'lg:mb-16'
      }

      return `${classes[this.marginBottom]} ${lgClasses[this.lgMarginBottom]}`
    },
    formattedHeadline () {
      return this.headline && this.headline.html ? this.headline.html : ''
    }
  }
}
